<template>
  <div>
    <!-- 中文 -->
    <div v-if="$store.state.show">
      <h2>第三方集成相关</h2>
      <p>如需集成帮助，请在技术支持群内提问。</p>
    </div>
    <!-- 英文 -->
    <div v-else>
      <h2>Third-party integration related</h2>
      <p>
        For integration assistance, ask questions in the technical support
        group.Í
      </p>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
h2 {
  font-size: 20px;
  font-weight: 500;
  color: #3c4858;
}
p {
  width: 763px;
  margin: 20px 0;
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  color: #3c4858;
}
</style>